<template>
  <div id="app">
    <section class="section-background">
      <div id="whoami">
        <div>
          <h1 class="unseen-left-1 unseen">Welcome!</h1>
          <div class="unseen-left-2 unseen">
            <p>My name is Josef Vávra.</p>
            <p>I am a software engineer.</p>
          </div>
        </div>
        <div class="unseen-left-25 unseen">
          <img id="face" src="./assets/images/face.jpg" alt="Josef Vávra">
        </div>
      </div>
    </section>
    <div class="spacer layer-first"></div>
    <div class="spacer layer-second"></div>
    <section class="section-background">
      <div id="aboutme">
        <p class="unseen-left-1 unseen">I hold a bachelor's degree in web and software engineering from the esteemed Czech
          Technical University, and
          I've spent {{ yearsOfExpertise }} years honing my skills in the ever-evolving world of technology.</p>
        <p class="unseen-left-15 unseen">My journey into the world of software engineering started when I was younger and
          encountered a simple virus
          that shut down my computer. That initial frustration sparked a deep fascination with technology, setting me on a
          path of exploration and problem-solving that continues to this day.</p>
        <p class="unseen-left-15 unseen">I've had the privilege of working on a wide range of projects that have allowed
          me to hone my skills and
          broaden my knowledge.</p>
        <p class="unseen-left-2 unseen">My expertise lies in crafting elegant and efficient solutions to complex
          challenges. I thrive on the thrill of
          turning ideas into reality through clean, maintainable code and robust software architecture.</p>
        <p class="unseen-left-2 unseen">Let's build something great together!</p>
      </div>
    </section>
    <div class="spacer layer-third"></div>
    <div class="spacer layer-fourth"></div>
    <section class="section-background-white">
      <div id="partners">
        <div class="unseen-left-1 unseen">Companies I have worked with:</div>
        <div class="partners-wrapper unseen-left-15 unseen">
          <div><img class="partners-logo" src="./assets/images/partners/KB.png" alt="KB"></div>
          <div><img class="partners-logo" src="./assets/images/partners/csob-logo.png" alt="CSOB"></div>
          <div><img class="partners-logo" src="./assets/images/partners/STX.png" alt="STX"></div>
          <div><img class="partners-logo" src="./assets/images/partners/anywhere.png" alt="Anywhere"></div>
          <div><img class="partners-logo" src="./assets/images/partners/Seznam.png" alt="Seznam"></div>
        </div>
      </div>
    </section>
    <div class="spacer layer-fifth"></div>
    <div class="spacer layer-sixth"></div>
    <section class="section-background-white">
      <div id="contactme" class="unseen-left-1 unseen">
        <div>
          <p>Come checkout my projects at github:</p>
          <div class="gh">
            <a href="https://github.com/vavrajosef">
              <img src="./assets/images/github-mark.png" alt="github" />
            </a>
          </div>
        </div>
        <div>
          <p>Contact me!</p>
          <div id="contactme-elements">
            <div>
              <a href="https://www.linkedin.com/in/josef-vavra/">
                <svg xmlns="http://www.w3.org/2000/svg" height="5vw"
                  viewBox="0 0 448 512"><!--! Font Awesome Free 6.4.2 by @fontawesome - https://fontawesome.com License - https://fontawesome.com/license (Commercial License) Copyright 2023 Fonticons, Inc. -->
                  <path
                    d="M416 32H31.9C14.3 32 0 46.5 0 64.3v383.4C0 465.5 14.3 480 31.9 480H416c17.6 0 32-14.5 32-32.3V64.3c0-17.8-14.4-32.3-32-32.3zM135.4 416H69V202.2h66.5V416zm-33.2-243c-21.3 0-38.5-17.3-38.5-38.5S80.9 96 102.2 96c21.2 0 38.5 17.3 38.5 38.5 0 21.3-17.2 38.5-38.5 38.5zm282.1 243h-66.4V312c0-24.8-.5-56.7-34.5-56.7-34.6 0-39.9 27-39.9 54.9V416h-66.4V202.2h63.7v29.2h.9c8.9-16.8 30.6-34.5 62.9-34.5 67.2 0 79.7 44.3 79.7 101.9V416z" />
                </svg>
              </a>
            </div>
            <div>
              <a href="mailto: vavra.pepa@gmail.com">
                <svg xmlns="http://www.w3.org/2000/svg" height="5vw"
                  viewBox="0 0 512 512"><!--! Font Awesome Free 6.4.2 by @fontawesome - https://fontawesome.com License - https://fontawesome.com/license (Commercial License) Copyright 2023 Fonticons, Inc. -->
                  <path
                    d="M48 64C21.5 64 0 85.5 0 112c0 15.1 7.1 29.3 19.2 38.4L236.8 313.6c11.4 8.5 27 8.5 38.4 0L492.8 150.4c12.1-9.1 19.2-23.3 19.2-38.4c0-26.5-21.5-48-48-48H48zM0 176V384c0 35.3 28.7 64 64 64H448c35.3 0 64-28.7 64-64V176L294.4 339.2c-22.8 17.1-54 17.1-76.8 0L0 176z" />
                </svg>
              </a>
            </div>
          </div>
        </div>
      </div>
    </section>
  </div>
</template>

<script setup>
import { onMounted } from 'vue';
const yearsOfExpertise = new Date().getFullYear() - 2015;
onMounted(() => {
  const observer = new IntersectionObserver((entries) => {
    entries.forEach((e) => {
      if (e.isIntersecting) {
        e.target.classList.add('show');
      } else {
        e.target.classList.remove('show')
      }
    });
  });

  document.title = "Josef Vávra"
  const unseenElements = document.querySelectorAll('.unseen')
  unseenElements.forEach((e) => observer.observe(e));
})


</script>

<style src="./assets/styles/main.css"></style>
